body .mat-unthemed.mat-mdc-button-base {
  width: fit-content;
  box-shadow: none;
  border-radius: 33px;
}

body .mat-mdc-button {
  border-radius: 33px;
}
body .mat-mdc-raised-button {
  background: var(--primary-blue) !important;
  color: white !important;
}

body .red.mat-mdc-raised-button {
  background: var(--primary-red) !important;
}

body .blue-color.mat-mdc-button .mdc-button__label {
  color: #006fdc !important;
}
body .red-color.mat-mdc-button .mdc-button__label {
  color: #db6e6e !important;
}

.mat-mdc-raised-button .mdc-button__label {
  height: 40px;

  font-family: "Lexend";
  line-height: 150%;
  height: 36px;
  font-size: 16px;
  height: fit-content;
}

body .mat-mdc-button .mdc-button__label {
  color: var(--primary-dark-blue);
  font-family: "Lexend";
  line-height: 150%;
  height: 36px;
  font-size: 16px;
  height: fit-content;
}

body .underline.mat-mdc-button .mdc-button__label {
  color: var(--primary-blue);
  text-decoration: underline;
}
