.mat-mdc-paginator-page-size-label {
  display: none;
}

body .mat-mdc-paginator-container {
  justify-content: center;
  padding: 15px 0;
  border: 1px solid #e5e8f2;
}

body .mat-mdc-paginator-container .paginatorRequests {
  padding: 0;
}

.mat-mdc-form-field {
  padding-top: 0;
}

.mat-mdc-paginator-range-label,
.mat-mdc-select-min-line {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 150%;
  font-weight: 400;
}

@media screen and (max-width: 500px) {
  body .mat-mdc-paginator-container {
    display: grid;
    justify-items: center;
    gap: 10px;
  }
}
