/* .mat-mdc-slide-toggle .mdc-switch__icon.mdc-switch__icon--off, */
.mat-mdc-slide-toggle {
  fill: #F9F9F9;
  --mdc-switch-unselected-icon-color: #F9F9F9;
  --mdc-switch-selected-icon-color: #F9F9F9;



  --mdc-switch-unselected-track-color: #636979;
  --mdc-switch-unselected-focus-track-color: #636979;
  --mdc-switch-unselected-hover-track-color: #636979;
  --mdc-switch-unselected-pressed-track-color: #636979;


  --mdc-switch-selected-track-color: #0078D4;
  --mdc-switch-selected-hover-track-color: #0078D4;

  --mdc-switch-selected-hover-state-layer-color: #0078D4;
  --mdc-switch-selected-focus-track-color: #0078D4;
  --mdc-switch-selected-pressed-track-color: #0078D4;

  --mdc-switch-selected-pressed-state-layer-color: #0078D4;
  --mdc-switch-selected-focus-state-layer-color: #0078D4;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  --mdc-switch-track-height: 22px;
  --mdc-switch-track-shape: 20px;
}

.mat-mdc-slide-toggle .mdc-switch__shadow {
  background-color: #F9F9F9;
}

.mat-mdc-slide-toggle .mdc-switch--unselected .mdc-switch__handle-track .mdc-switch__handle {
  left: 1px;
}

.mat-mdc-slide-toggle .mdc-switch--selected .mdc-switch__handle-track .mdc-switch__handle {
  left: -1px;
}