.mat-mdc-form-field .mdc-text-field {
  border-radius: 6px;
  background: #fff;
  border: 1px solid #c3cef8;
}

.mat-mdc-form-field .mdc-text-field--invalid {
  border: 1px solid var(--primary-red);
}

.mat-mdc-floating-label .optional {
  color: var(--primary-grey);
  font-size: 14px;
}

.mat-mdc-form-field-infix {
  min-height: 46px;
  display: flex;
  align-items: center;
}

.mat-mdc-form-field .mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field {
  padding-top: 24px;

  width: 100%;
}

.mat-mdc-form-field-infix .mdc-text-field__input::placeholder {
  display: block;
  opacity: 1;
}

.mdc-text-field .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding: 0;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label > * {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 14px !important;
  font-family: "Lexend";
  font-style: normal;
  line-height: 130%;
}

body
  .mdc-text-field--no-label:not(.mdc-text-field--textarea)
  .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 14px !important;
  font-family: "Lexend";
  font-style: normal;
  line-height: 130%;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea)
  .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  /* height: 46px !important; */

  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.mat-mdc-form-field-focus-overlay {
  background: #fff;
}

.mat-mdc-form-field .mdc-text-field {
  overflow: unset;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  left: -16px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: -26px;
  transform: unset;
}

body .mat-mdc-form-field-error-wrapper {
  padding: 0;
  margin-top: 5px;
}
