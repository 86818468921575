body .mdc-menu-surface.mat-mdc-select-panel {
  margin-top: 10px;
  border: 1px solid #c3cef8;
  box-shadow: 0px 4px 8px 5px rgba(206, 209, 226, 0.3);
  border-radius: 6px;
  padding: 0;
}

body
  .cdk-overlay-pane:not(.mat-mdc-select-panel-above)
  .mdc-menu-surface.mat-mdc-select-panel {
  border-radius: 6px;
}

body .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px;
}

body .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
  display: none;
}

body .mat-mdc-select-arrow-wrapper::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.5s;
  transform: scaleY(-1);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13 7L7 1L1 7' stroke='%23636979' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
}

body .mat-focused .mat-mdc-select-arrow-wrapper::after {
  transform: scaleY(1);
  transition: 0.5s;
}

body .mat-form-field-hide-placeholder .mat-mdc-select-placeholder {
  color: var(--primary-grey);
  -webkit-text-fill-color: var(--primary-grey);
}

body
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: 0;
  padding-bottom: 0;
}
