body .mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 12px;
  --mdc-dialog-container-elevation: 0px 4px 10px rgba(142, 148, 163, 0.4);
  width: calc(100% - 30px);
  margin: auto;
}

body .cdk-overlay-dark-backdrop {
  background: rgba(4, 12, 32, 0.3);
  backdrop-filter: blur(2px);
}

body .mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: 0px 4px 10px rgba(142, 148, 163, 0.4);
  border-radius: 12px;
}
