.mat-mdc-tab-group {
  gap: 24px;
}

.mat-mdc-tab-group .mat-mdc-tab-labels {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  gap: 24px;
  user-select: none;

  border-bottom: 1px solid var(--light-grey);
}

.mat-mdc-tab-group .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 31px;
  overflow: visible;
}

.mat-mdc-tab-group .mdc-tab-indicator {
  top: 1px;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  padding: 0px;
  width: fit-content;
  min-width: fit-content;
}

.mat-mdc-tab-group,
.mat-mdc-tab-body-wrapper {
  height: 100%;
}

.mat-mdc-tab-group.wrap .mat-mdc-tab-labels {
  border-bottom: none;
}

@media screen and (max-width: 1024px) {
  .mat-mdc-tab-group.wrap .mat-mdc-tab-labels {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    border-bottom: none;
  }
}
