:root {
  --mdc-filled-text-field-container-color: #ffffff;
}

* {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  font-size: 16px;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

html {
  height: 100%;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}

body {
  line-height: 1;
  opacity: initial;
}

main {
  height: 100%;
}
small {
  color: #636979;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
}

hr {
  border: unset;
  margin: 0;
}

div {
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
