:root {
  --primary-blue: #006fdc;
  --primary-dark-blue: #0c2935;
  --primary-light-blue: #e7edf8;
  --primary-grey: #636979;
  --primary-red: #db6e6e;
  --light-grey: #e5e8f2;

  --ListMarkIcon: url(./app/modules/icons/blue-diamond/blue-diamond.svg);
}

.Lexend-12 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 130%;
}

.Lexend-14 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
}

.Lexend-16 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 150%;
}

.Lexend-18 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 150%;
}

.Lexend-20 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 20px;
  line-height: 150%;
}

.Lexend-24 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 150%;
}

.Lexend-32 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 32px;
  line-height: 150%;
}

.Lexend-54 {
  font-family: "Lexend", sans-serif;
  font-style: normal;
  font-size: 54px;
  line-height: 150%;
}

.pointer {
  cursor: pointer;
}

.white {
  color: #ffffff;
}

.grey {
  color: var(--primary-grey);
}

.blue {
  color: var(--primary-blue);
}

.red {
  color: var(--primary-red);
}

dialog::backdrop {
  background: rgba(4, 12, 32, 0.3);
  backdrop-filter: blur(2px);
}

.dark-blue {
  color: var(--primary-dark-blue);
}

.light-blue {
  color: var(--primary-light-blue);
}

.center {
  text-align: center;
}

.end {
  text-align: end;
}

.start {
  text-align: start;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}

.rotate-90 {
  transform: rotate(90deg);
  transform-origin: center;
}

.rotate-180 {
  transform: rotate(180deg);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

*:has(> svg:not([class*="mat-calendar-arrow"])) {
  display: grid;
}

h1 {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;

  color: #0c2935;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.resize-icon {
  position: absolute;
  right: -24px;
  cursor: col-resize;
  transform: translate(-50%);
  height: 56px;
  width: 24px;
  top: calc(50% - 24px);
  background-image: url("./app/modules/icons/resize/resize.svg");
}

input[type="number"] {
  -moz-appearance: textfield;
}

.gridGap {
  display: grid;
  gap: 24px;
  grid-template-rows: auto 1fr;
}

button:disabled {
  opacity: 0.5;
}
