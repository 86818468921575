body .mat-mdc-table.cdk-table {
  padding: 20px;
  box-shadow: none;
}

.mat-mdc-table.cdk-table th {
  font-family: Lexend;
  font-style: normal;
  line-height: 150%;
  font-weight: 500;
}

.mat-mdc-table.cdk-table td {
  font-family: Lexend;
  font-style: normal;
  line-height: 150%;
  font-weight: 400;
  color: var(--primary-grey);
}

.mdc-data-table__content
  .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background: #fbfbfb;
}

.mat-mdc-table.cdk-table td,
.mat-mdc-table.cdk-table th {
  border-bottom: 1px solid #ebedf9;
  height: 60px;
}

.mat-mdc-table.cdk-table thead {
  background: #fff;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 4px 10px rgba(142, 148, 163, 0.1);
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .wrap {
    overflow: visible;
    max-height: 100%;
    width: 100%;
  }

  .container {
    max-height: unset;
  }

  .mat-mdc-table.cdk-table thead tr {
    display: none;
  }

  .mat-mdc-table.cdk-table tbody tr {
    border-bottom: 1px solid #ebedf9;
  }

  .mat-mdc-table.cdk-table tr,
  .mat-mdc-table.cdk-table tr > td {
    display: block;
    height: 100%;
  }

  .mat-mdc-table.cdk-table tr > td {
    white-space: break-spaces;
  }

  .mat-mdc-table.cdk-table tr td::before {
    content: attr(data-name) ": ";
    font-weight: 600;
    color: var(--primary-dark-blue);
  }

  .mat-mdc-table.cdk-table tr > td,
  .mat-mdc-table.cdk-table tr > th {
    height: unset;
    padding: 15px;
  }

  .mat-mdc-table.cdk-table tr > td {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: start;
    border: none;
  }
}
