body .cdk-overlay-connected-position-bounding-box {
  /* margin-top: 5px; */
}

body .mat-mdc-menu-content {
  background: #ffffff;
  border: 1px solid var(--light-grey);
  box-shadow: 0px 4px 8px 5px rgba(206, 209, 226, 0.3);
  border-radius: 6px;
  padding: 0;
  box-shadow: none;
}

body .mat-mdc-menu-panel.mat-mdc-menu-panel {
  box-shadow: none;
}

body .mat-mdc-menu-item .mdc-list-item__primary-text {
  font-family: "Lexend";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #636979;
}

body .mat-mdc-menu-panel.mat-mdc-menu-panel svg {
  content: '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0.999999L7 7L1 13" stroke="#636979" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>';
}

body .mat-mdc-menu-panel.mat-mdc-menu-panel svg::after {
  width: 5px;
  height: 5px;
  content: "";
  background: black;
}
