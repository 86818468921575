body .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
body .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
body .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  background: var(--primary-blue);
  border: 2px solid  var(--primary-blue);

}

body .mdc-checkbox .mdc-checkbox__ripple {
  display: none;
}

body .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: var(--primary-blue);
  background: white;
}


body .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: var(--primary-blue);
  opacity: 0.7;
}

body .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked~.mdc-checkbox__background,
body .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:indeterminate~.mdc-checkbox__background {
  background: var(--primary-blue);
  border-color: var(--primary-blue);
}

body .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate)~.mdc-checkbox__background {
  border-color: var(--primary-blue);
}
