.mdc-line-ripple {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #FFFFFF;
}

.mat-mdc-form-field .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  display: none;
  opacity: 0;
  background-color: #FFFFFF;
}

.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error {
  padding: 0;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  font-family: monospace;
}
/* .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  height: 100%;
}

*/
