body
  .cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above)
  .mdc-menu-surface.mat-mdc-autocomplete-panel {
  margin-top: 6px;
  border-radius: 6px;
  border: 1px solid #c3cef8;
  box-shadow: 0px 4px 8px 5px rgba(206, 209, 226, 0.3);
  padding: 0;
}

.mat-mdc-option.mdc-list-item {
  font-family: Lexend;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: var(--primary-grey);
}
